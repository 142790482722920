<template>
  <v-card v-if="!loading" flat color="transparent">
    <v-dialog v-model="dialog" max-width="600">
      <cropper :src="srcData" :accept="accept" @input="input" @cancel="cancel" />
    </v-dialog>
    <v-card
      @click="validate()"
      class="v-sheet--offset mx-auto"
      elevation="0"
      max-width="266px"
      outlined
    >
      <transition name="scale-transition">
        <v-img :src="url ? url : url_default" aspect-ratio="1" class="grey lighten-2" contain>
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </transition>
    </v-card>
    <v-card-actions class="ml-7">
      <v-file-input
        v-model="file"
        v-if="showFileInput"
        @change="open()"
        show-size
        v-bind="$attrs"
        :label="'Upload ' + label"
        solo-inverted
        flat
        dense
        :accept="accept"
        hide-details
      >
      </v-file-input>
    </v-card-actions>
  </v-card>
  <v-card v-else flat>
    <v-card-title>
      <v-spacer />
      <v-progress-circular indeterminate :size="60" :width="1"></v-progress-circular>
      <v-spacer />
    </v-card-title>
  </v-card>
</template>
<script>
import _ from 'lodash';
import Cropper from './Cropper';
import PccButtonTooltip from '../Components/PccButtonTooltip';

export default {
  name: 'cropper-component',
  components: {
    cropper: Cropper,
    'pcc-button-tooltip': PccButtonTooltip,
  },
  data: function () {
    return {
      id: Date.now(),
      file: null,
      showFileInput: false,
      loading: false,
      cropper: null,
      cropperObj: null,
      dialog: false,
      srcData: '',
    };
  },
  props: {
    url: {
      type: String,
      required: false,
      default: null,
    },
    url_default: {
      type: [String, Object],
      required: false,
      default: null,
    },
    label: {
      type: String,
      required: false,
      default: null,
    },
    accept: {
      type: String,
      required: false,
      default: 'image/*',
    },
  },
  methods: {
    validate() {
      let user = JSON.parse(localStorage.getItem('USER_KEY'));
      if (user.admin || user.roles.thumbnail) {
        this.showFileInput = !this.showFileInput;
      } else {
        this.showFileInput = false;
      }
    },

    open() {
      let selectedfile = this.file;
      if (selectedfile) {
        let imageFile = selectedfile;
        let fileReader = new FileReader();
        fileReader.onload = (fileLoadedEvent) => {
          this.srcData = fileLoadedEvent.target.result;
          this.dialog = true;
        };
        fileReader.readAsDataURL(imageFile);
      }
    },
    input(url) {
      this.$emit('input', url);
      this.dialog = false;
      this.file = null;
      this.showFileInput = false;
    },
    cancel() {
      this.dialog = false;
      this.file = null;
      this.showFileInput = false;
    },
  },
};
</script>
<style scoped>
#thumbnail {
  display: block;
  max-width: 100%;
}
.v-dialog {
  position: absolute;
  bottom: 0;
  right: 0;
}
.img-container {
  max-width: 100%;
  max-height: 360px;
}
/* Ensure the size of the image fit the container perfectly */
img {
  display: block;
  /* This rule is very important, please don't ignore this */
  max-width: 100%;
}
</style>
